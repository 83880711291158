import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import emailjs from 'emailjs-com';
import ReactGA4 from 'react-ga4'; // Use react-ga4 for GA4 support

const root = ReactDOM.createRoot(document.getElementById('root'));

//Initialize EmailJS with public key
emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY)

// Initialize Google Analytics 4 with your GA4 Measurement ID
ReactGA4.initialize('G-1DJCK8954R'); // GA4 Measurement ID

// Track initial pageview
ReactGA4.send('pageview', window.location.pathname + window.location.search);

root.render(
  <Router>
    <App />
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
