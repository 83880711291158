import { useEffect, useState, useRef } from 'react';
import './App.css';
import Header from './Components/Header';
import Hero from './Components/Hero';
import KeyFeatures from './Components/KeyFeatures';
import CloudComputing from './Components/CloudComputing';
import KeyFeat from './Components/KeyFeat';
import WhyUs from './Components/WhyUs';
import Footer from './Components/Footer';
import Demo from './Components/Demo';
import { ToastContainer } from 'react-toastify';
import {MdArrowDownward} from 'react-icons/md';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom'; // Import useLocation for route tracking
import ReactGA4 from 'react-ga4'; // Import react-ga4 for GA4 support

const App = () => {
  const [loading, setLoading] = useState(true);
  const demoRef = useRef(null);  // Create a ref for Demo component
  const [scrollPosition, setScrollPosition] = useState(0);
  const location = useLocation(); // Get current route location

  // Function to scroll to the Demo component
  const handleBookDemo = () => {
    if (demoRef.current) {
      demoRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('demoRef is not set properly');
    }
  };

  const handleScroll = () => {
    const newScrollPosition = scrollPosition + window.innerHeight;
    window.scrollTo({
      top: newScrollPosition,
      behavior: 'smooth'
    });
    setScrollPosition(newScrollPosition);
  };

     // Track pageview on route change (for GA4)
  useEffect(() => {
    ReactGA4.send('pageview', location.pathname + location.search); // Track pageview on route change
  }, [location]); // Trigger whenever the route changes

  // For loading a page
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="bg-main-gradient from-bgColorTwo flex flex-col to-bgMainColor">
      {/* Pass handleBookDemo to Header */}
      <Header handleBookDemo={handleBookDemo} />
      <Hero />
      <KeyFeatures />
      <CloudComputing />
      <KeyFeat />
      <WhyUs />
      {/* Pass demoRef to Demo */}
      <Demo ref={demoRef} />
      <Footer />
      <div 
        className="fixed bottom-8 left-1/2 transform -translate-x-1/2 z-10"
        onClick={handleScroll}
      >
        <div className="p-2 bg-bgColor text-white rounded-full shadow-lg animate-bounce">
          <MdArrowDownward size={30} />
        </div>
      </div>

      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;