import React, { useEffect } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css'
import dataRetention from '../assets/data/dataRetention';

const KeyFeatures = () => {
    useEffect(()=>{
        Aos.init({duration: 3000});
    }, []);
    
    return (
        <div className='lg:pl-20 p-10 lg:pr-20 2xl:pr-60 2xl:pl-60' data-aos="fade-up" data-aos-offset="50">
            <div className='bg-keysColor rounded-3xl p-10 lg:pt-6 2xl:pt-14 xl:pt-10 md:w-full md:h-full flex-wrap lg:w-full lg:h-auto h-auto w-full xl:pl-10 xl-pr-10 lg:pl-16 lg:pr-16 lg:pb-16'>
                <h3 className='text-white text-2xl leading-snug font-bold text-center md:text-dataRent md:block'>
                    Data Retention Key Features
                </h3>
                <div className='grid md:grid-cols-2 grid-cols-1 pt-4 lg:grid-cols-3 2xl:pt-8 2xl:gap-14 gap-4'>
                    {dataRetention.map((item, index) => (
                        <div 
                            key={index} 
                            className='lg:w-full w-auto md:w-mBoxes bg-keysColorOne border-2 border-heroBorder flex flex-col gap-2 p-4 lg:flex-col lg:pl-6 lg:pr-6 lg:pt-4 2xl:pt-4 lg:h-boxes rounded-3xl'
                            data-aos={index % 2 === 0 ? "fade-down" : "fade-right"} // Add variation to the AOS animations
                        >
                            <img src={item.img} alt={item.title} className='lg:h-vectorHeight lg:w-vectorWidth w-mdvectorWidth h-mdvectorHeight' />
                            <h3 className='font-semibold text-white md:text-4xl'>{item.title}</h3>
                            <p className='text-base text-dataRentp'>{item.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default KeyFeatures;
