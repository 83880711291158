import React, { useEffect } from 'react'
import andurar from "../assets/andurar-lo.png";
import 'aos/dist/aos.css';
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneFlip } from "react-icons/fa6";
import Aos from 'aos';
const Footer = () => {
    let year = new Date().getFullYear();
    useEffect(()=>{
        Aos.init({duration:3000});
    },[])
  return (
    <div className='bg-footerBg p-10 mt-8 lg:mt-10 flex 2xl:pr-60 2xl:pl-60 flex-col lg:flex-col lg:pl-20 lg:pr-20 lg:pb-20' data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
        <div className='flex flex-col gap-10 lg:flex-row lg:pt-16 2xl:gap-40 lg:gap-20'>
            <div className='lg:h-40 gap-4 lg:w-96 flex-col flex lg:flex-col lg:gap-4'>
                <div className='flex'>
                <img src={andurar} alt="" />
                </div>
                <p className='text-footerText'>Andurar is an IT services and solution provider dedicated to providing innovative solutions for businesses to stay ahead of the game in the ever-changing digital landscape.</p>
            </div>
           <div className='flex justify-between gap-10'>
           <div className='flex flex-col lg:flex-col lg:gap-4'>
                <h4 className='text-cloudText font-bold text-xl'>Resources</h4>
                <a href="https://andurar.com/rpa" className='text-footerText' target="_blank"  rel="noreferrer"> Solutions</a>
                <a href="https://andurar.com/industries" className='text-footerText' target="_blank"  rel="noreferrer">Industries</a>
                <a href="https://andurar.com/cases" className='text-footerText' target="_blank"  rel="noreferrer">Case Studies</a>
            </div>
            <div className='flex flex-col lg:flex-col lg:w-40 lg:gap-4'>
                <h4 className='text-cloudText font-bold text-xl'>Company</h4>
                <a href="https://andurar.com/about" className='text-footerText' target="_blank"  rel="noreferrer">About Us</a>
                <a href="https://andurar.com/career" className='text-footerText' target="_blank"  rel="noreferrer">Careers</a>
                <a href="https://andurar.com/contact" className='text-footerText' target="_blank"  rel="noreferrer">Contact Us</a>
                <a href="https://andurar.com/terms" className='text-footerText' target="_blank"  rel="noreferrer">Terms & Conditions</a>
            </div>
           </div>
            <div className='flex flex-col lg:flex-col lg:w-96 lg:gap-4'>
                <h4 className='text-cloudText font-bold text-xl'>Contact</h4>
                <div className='flex gap-4'>
                <FaLocationDot className='text-footerText lg:mt-2' />
                    <p className='text-footerText'>3B 4th Floor Giaan Towers, Hospital Road, Community 11, Tema.</p>
                </div>
                <div className='flex gap-4'>
                <MdEmail className='text-footerText lg:mt-2'  />
                    <p className='text-footerText mt-2'>info@andurar.com</p>
                </div>
                <div className='flex gap-4'>
                <FaPhoneFlip className='text-footerText lg:mt-2'  />
                    <p className='text-footerText'>+233 50 432 5296</p>
                </div>
            </div>
        </div>
        <hr className='mt-10 mb-10'/>
      <div className='lg:pt-16 lg:justify-between flex lg:flex-row flex-col'>
        <p className='text-footerText'>Copyright &copy; {year}, Andurar All Rights Reserved</p>
        <a href="https://andurar.com/terms" className='text-footerText' target="_blank"  rel="noreferrer">Terms & Conditions</a>
      </div>
    </div>
  )
}

export default Footer
